import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { updateLoanType, getCustomerDetails, resetState } from 'actions/actions';
import { BLOCKS } from '@contentful/rich-text-types';
import breakpoint from 'styled-components-breakpoint';
import { Button } from 'reactstrap';

import Wordmark from 'images/wordmark.inline.svg';
import Location from 'images/location.inline.svg';
import Phone from 'images/phone.inline.svg';
import ExternalLink from 'images/external.link.inline.svg';
import Right from 'images/right.inline.svg';

import Box from 'components/Common/Layout/Box';

const StyledWordmark = styled(Wordmark)`
  width: 129px;
  height: 39px;
  align-self: center;
  margin-top: 0;
  margin-bottom: 32px;

  ${breakpoint('lg')` 
    margin-top: 48px;
  `}
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  ${props => props.theme.main.fonts.application.h2};
  margin-bottom: 24px;
`;

const Message = styled.div`  
  p {
    color: black;
    ${props => props.theme.main.fonts.body.normal};
    &:first-of-type {
      margin-top:0;
    }
    &:last-of-type {
      margin-bottom:0;
    }
  }
  ul {
    padding-left: 32px;
    margin-top: -12px;
    li {
      ${props => props.theme.main.fonts.body.normal};
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.main.colors.secondary.dark1};
    &:hover {
      text-decoration: none;
    }
  }
  button.btn {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.secondary.dark1};
    margin: 0;
    padding: 0;
    margin-top: 0;
  }
`;

const StyledBox = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  button.btn {
    ${props => props.theme.main.fonts.body.normal};
    color: ${props => props.theme.main.colors.secondary.dark1};
    align-self: flex-start;
    margin-top: 24px;
    padding-left: 0;
  }

  ul.help {
    margin-top: 32px;
    margin-bottom: 0;
    padding-top: 32px;
    border-top: 2px solid ${props => props.theme.main.colors.neutral.line};
    padding-left: 0;
    list-style: none;
    li {
      margin-bottom: 21px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const AnchorLink = styled.a`
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.grey.base};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.main.colors.grey.base};
    text-decoration: none;
  }
  svg {
    path {
      fill: ${props => props.theme.main.colors.primary.base};
    }
  }

`;

const Link = styled.a`
  &&& {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

`;


export default ({errorCode, alerts = [], cancel = false, timeout = false, unauthorized = false}) => {
  const dispatch = useDispatch();
  const { accessToken, isMobile } = useSelector(state => state.reducer);
  const result = useStaticQuery(graphql`
    query {
      allContentfulError {
        nodes {
          errorCode
          heading
          message {
            json
          }
          displayLinkToFairAndFastLoan
          errorReasons {
            errorCode
            reasonText
          }
        }
      }
    }
    `);

  const contentfulError = !cancel && !timeout && !unauthorized ? result.allContentfulError.nodes.find(error => parseInt(error.errorCode) === parseInt(errorCode)) : null;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if(children[0].includes('{{reasons}}')) {
          const paragraph = children[0].split('{{reasons}}')[0];
          return <><p>{paragraph}</p>{contentfulError.errorReasons ? (alerts ? <ul>{alerts.map((alert, i) => (<li key={i}>{contentfulError.errorReasons.findIndex(reason => reason.errorCode === alert.key) >= 0 ? contentfulError.errorReasons.find(reason => reason.errorCode === alert.key).reasonText : '-'}</li>))}</ul>: null) : null}</>
        }
        else {
          return <p>{children}</p>
        }
      }
    }
  };



  const redirectToFFLoan = () => {
    dispatch(resetState());
    dispatch(getCustomerDetails(accessToken, isMobile, () => {
      dispatch(updateLoanType('OnlineFairAndFastLoan'));
      navigate('loan-calculator', { replace: true });
    }));
  }

  const startNewLoan = () => {
    dispatch(resetState());
    dispatch(getCustomerDetails(accessToken, isMobile, () => {
      navigate('loan-type', { replace: true });
    }))
  }

  const goToOnlineBanking = (e) => {
    e.preventDefault();
    if(isMobile) {
      window.location.replace('/logout#closeMobile');
    }
    else {
      window.location.replace(`${process.env.GATSBY_VANCITY_URL}/OnlineBanking/MyAccounts/`);
    }
  }

  const findABranch = (e) => {
    e.preventDefault();
    window.location.replace(`${process.env.GATSBY_VANCITY_URL}/ContactUs/FindBranchATM`);
  }

  if((cancel || timeout) && isMobile) {
    window.location.replace('/logout#closeMobile');
  }
  
  return (
    <Row>
      {!((cancel || timeout) && isMobile) ? 
        <StyledCol md={{size: 6, offset: 3}} sm={{size: 8, offset: 2}}>
          <StyledWordmark />
          <StyledBox shadow>
            <Heading data-testid="error-title">{unauthorized ? 'You are not logged in' : cancel ? 'Loan application cancelled' : timeout ? 'Loan application cancelled' : contentfulError.heading}</Heading>
            {unauthorized ? <Message data-testid="error-message">In order to access this application, you must be logged in to your online banking. <br /><br /><Link tabIndex="1" onClick={goToOnlineBanking}>Log in to Online Banking <Right /></Link></Message>
            : cancel ? <Message data-testid="error-message">Your loan application has been cancelled successfully. You may now close this window or <a tabIndex="1" onClick={startNewLoan}>start a new loan application</a>.</Message>
            : timeout ? <Message data-testid="error-message">For your security, we cancelled your loan application due to inactivity. You may now close this window or <a tabIndex="1" onClick={startNewLoan}>start a new loan application</a>.</Message>
            : <Message data-testid="error-message">{documentToReactComponents(contentfulError.message.json, options)}</Message>}
            {contentfulError && contentfulError.displayLinkToFairAndFastLoan ? <Button color="link" onClick={redirectToFFLoan} name="button-applyFairFastLoan">Apply for a Fair &amp; Fast loan <Right /></Button>:null}          
            <ul className="help">
              <li><AnchorLink href="tel:18888262489" iconColor="primary"><Phone /> 1-888-826-2489</AnchorLink></li>
              {!isMobile ? <li><AnchorLink onClick={findABranch} iconColor="primary" tabIndex="0"><Location /> Find a branch</AnchorLink></li> : null}
              {!unauthorized ? <li><AnchorLink onClick={goToOnlineBanking} iconColor="grey" tabIndex="0"><ExternalLink /> Back to online banking</AnchorLink></li> : null}
            </ul>
          </StyledBox>
        </StyledCol>
        : null}
    </Row>
  );
}