import React from 'react'

import Layout from 'components/Layout/Layout';
import Error from 'components/Error/Error';
import SEO from 'components/seo';

const UnauthorizedPage = () => (
  <Layout displayProgressBar={false}>
    <SEO title="Unauthorized" />
    <Error unauthorized={true} />
  </Layout>
)

export default UnauthorizedPage;
